import React from 'react';
import { Helmet } from "react-helmet"
import MenuBar from '../components/MenuBar';
import { graphql, navigate } from 'gatsby';
import ProjectPreview from '../components/ProjectPreview';
import get from 'lodash/get'
import { GatsbyImage } from 'gatsby-plugin-image';
import '../styles/project.css'
import { renderRichText } from 'gatsby-source-contentful/rich-text'
import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer'
import ImageFullScreen from '../components/ImageFullScreen';
import Video from '../components/Video';
import Dock from '../components/Dock';

class Project extends React.Component {
  render() {
    const post = get(this, 'props.data.contentfulProject')

    return (
      <main>
        <Helmet>
          <meta charSet="utf-8" />
          <meta name="viewport" content="width=device-width, initial-scale=1" />
          <meta name="theme-color" content="black" />
          <title>{post.title} - Portfolio - HYSG</title>
          <link rel="canonical" href="https://mysite.com/example" />
        </Helmet>
        <MenuBar />
        <div className='project-container'>
          {
            //<h1 className='title project-title'>{post.title}</h1>
          }
          <img src={post.mainImage.resize.src} className='project-main-image' />
          <Video videoSrcURL={post.videoEmbedUrl} />
          <div className='project-gallery'>
            <div className='gallery-column'>
              {post.galleryColumn1 && post.galleryColumn1.map(gallery => (
                <img src={gallery.resize.src} className='project-gallery-image' />
              ))}
            </div>
            <div className='gallery-column'>
              {post.galleryColumn2 && post.galleryColumn2.map(gallery => (
                <img src={gallery.resize.src} className='project-gallery-image' />
              ))}
            </div>
            <div className='gallery-column'>
              {post.galleryColumn3 && post.galleryColumn3.map(gallery => (
                <img src={gallery.resize.src} className='project-gallery-image' />
              ))}
            </div>
          </div>
          {
            post.body && <p className='caption'>{renderRichText(post.body)}</p>
          }
          {/*future feature to click image and show full screen <ImageFullScreen image={post.mainImage.resize.src}/>*/}
        </div>
        <Dock />
      </main>
    )
  }
}

export default Project

export const pageQuery = graphql`
  query ProjectByURL($url: String!) {
    contentfulProject(url: { eq: $url }) {
        title
        url
        mainImage {
          resize(quality: 60, height: 400, format: WEBP) {
            src
          }
        }
        videoEmbedUrl
        galleryColumn1 {
          resize(width: 1080, quality: 65, format: WEBP) {
            src
          }
        }
        galleryColumn2 {
          resize(width: 1080, quality: 65, format: WEBP) {
            src
          }
        }
        galleryColumn3 {
          resize(width: 1080, quality: 65, format: WEBP) {
            src
          }
        }
        body {
            raw
          }
    }
  }
`